<template>
    <main>
        <header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
            <div class="container-fluid">
                <div class="page-header-content">
                    <div class="row align-items-center justify-content-between pt-3">
                        <div class="col-auto mb-3">
                            <h1 class="page-header-title">
                            Consulta de Usuarios
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="container-xl px-4 mt-2">            
            <div class="row gutters-sm">
                <div class="col-md-12 mb-3">
                    <div class="card text-center card-waves mt-2 card-header-actions">
                        <div class="card-header ">
                            Usuario
                            <usuario-field v-model="usuario" :validator="$v.usuario" :showName="false"></usuario-field>
                        </div>
                        <div class="card-body " >
                            <div v-if="Object.keys(usuario).length">
                                <div class="form-group">
                                    <div v-if="usuario.sexo === 'F'">
                                        <img class="img-fluid " src="@/assets/img-genero/mujer.png" width="96">                                        
                                    </div>
                                    <div  v-else-if="usuario.sexo === 'M'">
                                        <img class="img-fluid" src="@/assets/img-genero/hombre.png" width="96">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <strong >{{usuario.nombre_1}} {{usuario.nombre_2}} {{usuario.apellido_1}} {{usuario.apellido_2}}</strong>
                                    <h6 class="text-body mt-2">FECHA DE NACIMIENTO: {{usuario.fecha_nacimiento}}</h6>
                                    <h6 class="text-body">EDAD : <span class="badge badge-secondary">{{usuario.years}}</span> </h6>
                                </div>
                                <VictimArmedConflict v-show="Number(usuario.victima_conflicto) === 1"></VictimArmedConflict>
                                <div class="form-group mt-2">
                                    <button class="btn btn-primary btn-sm lift lift-sm" @click="llenarHistoria();">Historia Clinica <i class="fa fa-plus fa-fw"></i></button>
                                </div>
                            </div>
                            <div class="align-center " v-else >
                                <img class="mb-0" src="@/assets/img-generales/error.png" width="256">
                                <p ><strong>Sin datos para mostrar...</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import {required} from 'vuelidate/lib/validators';
import UsuarioField from "../../../components/common/UsuarioField";
import VictimArmedConflict from '../../../components/common/alerts/VictimArmedConflict.vue';
export default {
    name: "index.vue",
    components: { UsuarioField,VictimArmedConflict},
    data () {
        return {
            usuario : {},
        }
    },
    methods: {
        llenarHistoria(){
            this.$router.push({
                name:'historias.perfil.usuario',
                params:{
                    idUsuario:this.usuario.id,
                }
            })
        }
    },
    filters: {
        upper: function (value) {
            return value.toUpperCase();
        },
    },
    validations: {
        usuario : {required},
    },
    /* computed: {
        contratos_eps :  function(){
            if(isEmpty(this.usuarios.eps)) return [];
            return this.usuarios.eps.contratos;
        }
    }, */
}
</script>